import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rileyuy/Documents/GitHub/ep-vts-website/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
import ContactUs from "../components/StaticPages/HelpCenter/ContactUs";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container isCentered mdxType="Container">
      <SEO title="Help Center" mdxType="SEO" />
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`Order`}</h4>
      <Collapsible title="How do I order?" mdxType="Collapsible">
        <p>{`You can order your medicine by uploading your prescription and searching for your medicines.`}</p>
        <ol>
          <li parentName="ol">{`On the home page of viatris.medgrocer.com, click the “Order Medicines” button.`}</li>
          <li parentName="ol">{`Search and click for the medicine you want.`}</li>
          <li parentName="ol">{`Change the quantity of your order and click Add to cart.`}</li>
          <li parentName="ol">{`Repeat Steps 2 and 3 for any other medicine you want to purchase.`}</li>
          <li parentName="ol">{`Upload attachments such as prescription and employee ID and proceed to checkout.`}</li>
          <li parentName="ol">{`Fill out your information, payment method, and delivery address. Click Next.`}</li>
          <li parentName="ol">{`Read our terms and conditions, tick the box if you agree, and then click Submit.`}</li>
          <li parentName="ol">{`You’ll receive an email summarizing your order.`}</li>
          <li parentName="ol">{`Wait for the confirmation from our pharmacist to verify your order.`}</li>
          <li parentName="ol">{`After you confirm, we'll deliver your order within 2-5 working days
.`}</li>
        </ol>
      </Collapsible>
      <Collapsible title="How would I know if my order went through?" mdxType="Collapsible">
        <p>{`You will receive a confirmation email immediately after ordering. When your order is being processed, our pharmacist will contact you to verify your order. If you do not receive a confirmation from MedGrocer within 2 working days, you may contact us `}<Link to="/help-center#contact-us" className="has-text-weight-bold has-text-primary" mdxType="Link">{`here.`}</Link></p>
      </Collapsible>
      <Collapsible title="How long does it take to process my order?" mdxType="Collapsible">
        <p>{`Orders will be processed on Monday to Friday, 9AM to 5PM. Orders made during that time are processed within 2 business days.`}</p>
        <p>{`For deliveries in Metro Manila, your orders may arrive in 2-5 business days after you confirm your order.`}</p>
      </Collapsible>
      <Collapsible title="Can I modify my order?" mdxType="Collapsible">
        <p>{`To prevent miscommunications and minimize inaccuracies, orders are considered final upon checkout. For any revisions, please cancel your order by replying `}<strong parentName="p">{`CANCEL ORDER`}</strong>{` to the verification our pharmacist will send you and place a new order on viatris.medgrocer.com.`}</p>
      </Collapsible>
      <Collapsible title="How do I cancel my order?" mdxType="Collapsible">
        <p>{`You may only cancel your order `}<strong parentName="p">{`if it has not yet been shipped`}</strong>{`. Contact us at `}<a href="mailto:viatris.med@medgrocer.com" target="_blank" rel="noopener noreferrer">{` viatris.med@medgrocer.com `}</a>{` to confirm the status of your order, then discuss the details of your order with our team. If your order can be canceled, you will receive an order cancellation notification.`}</p>
      </Collapsible>
      <Collapsible title="Can I order for someone else?" mdxType="Collapsible">
        <p>{`Only active employees and registered dependents of Viatris can order avail of this benefit. You may go to our website, `}<a href='https://www.medgrocer.com'>{` medgrocer.com `}</a>{`, to avail of medicines outside the medicine program.`}</p>
      </Collapsible>
      <Collapsible title="Is there a minimum order?" mdxType="Collapsible">
        <p>{`There's no minimum order for delivery.`}</p>
      </Collapsible>
      <h4>{`Prescription`}</h4>
      <Collapsible title="Do I need a prescription to buy medicines?" mdxType="Collapsible">
        <p>{`Yes. Just like any pharmacy, we require a valid prescription that contains the following details: prescription date, patient’s name, prescriber’s name, and prescriber’s license number.`}</p>
        <p>{`All medicines ordered in the program require a prescription.`}</p>
      </Collapsible>
      <Collapsible title="How do I send my prescription?" mdxType="Collapsible">
        <p>{`You can upload a picture or a scanned copy of your prescription on the website.`}</p>
      </Collapsible>
      <Collapsible title="I can’t upload my prescription." mdxType="Collapsible">
        <p>{`If you can’t upload your prescription, please inform our pharmacist upon order confirmation and send it through email.`}</p>
      </Collapsible>
      <h4>{`Delivery`}</h4>
      <Collapsible title="What are the areas covered for delivery? Is there a convenience fee?" mdxType="Collapsible">
        <p>{`For your convenience, your medicines will be delivered to your company clinic. We will inform you once your order is ready for pick-up.`}</p>
      </Collapsible>
      <Collapsible title="Can I pick-up the medicines in the MedGrocer office?" mdxType="Collapsible">
        <p>{`Our operations are streamlined to give you a hassle-free delivery experience. Please use our delivery service for your convenience.`}</p>
      </Collapsible>
      <Collapsible title="When will I receive my order?" mdxType="Collapsible">
        <p>{`We process orders from 9AM-5PM on weekdays. After placing your order on the website, our pharmacist will verify your order. Your order may be delivered within 2-5 working days after you confirm your order.`}</p>
      </Collapsible>
      <h4>{`Returns`}</h4>
      <Collapsible title="What is your return policy?" mdxType="Collapsible">
        <p>{`All products sold on MedGrocer are subject to quality checks by our purchasing, storage, assembly, and delivery teams. Please inspect your order upon delivery. If your medicines are damaged upon delivery, you may refuse to accept the medicines.`}</p>
        <p>{`On a case-to-case basis, we can process requests to return or exchange the medicines within seven days upon delivery. Please make sure that you keep your delivery receipt and the medicines you want to return.`}</p>
      </Collapsible>
      <Collapsible title="What medicines will you accept for returns?" mdxType="Collapsible">
        <p>{`We will replace damaged or wrongly dispensed medicines.`}</p>
      </Collapsible>
      <Collapsible title="Can I return an item after seven days?" mdxType="Collapsible">
        <p>{`Any problems encountered seven days after the delivery may not be accommodated under our Return Policy. We highly encourage you to review and inspect your medicines upon delivery so that our courier could immediately facilitate the return, exchange, or refund request.`}</p>
      </Collapsible>
      <Collapsible title="How long is the processing time for returns?" mdxType="Collapsible">
        <p>{`In Metro Manila, returns are processed within two to five working days. For provincial locations, return orders are processed within 10 working days.`}</p>
      </Collapsible>
      <h4>{`Privacy`}</h4>
      <Collapsible title="What information do you share?" mdxType="Collapsible">
        <p>{`Only our pharmacists processing your orders have access to your medical information to ensure your medicines are properly dispensed. If you are an employee of our partner company, your orders are shared with your employers as healthcare provider to process payments. We may use anonymized data for analytics to improve our services or collaborate with partners.`}</p>
      </Collapsible>
      <Collapsible title="How do I contact the data privacy officer?" mdxType="Collapsible">
        <p>{`MedGrocer’s Data Privacy Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following information:`}</p>
        <p>{`MedGrocer 24F Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City `}<br />{`
Email address: `}<a href="mailto:dpo@medgrocer.com" className="has-text-weight-bold has-text-primary">{`dpo@medgrocer.com`}</a></p>
      </Collapsible>
      <h4>{`Medicine`}</h4>
      <Collapsible title="What is better for my condition, Drug A or Drug B?" mdxType="Collapsible">
        <p>{`To ask for pharmacy advice, you may message us through Facebook.`}</p>
      </Collapsible>
      <Collapsible title="How do I know the expiry of the medicines?" mdxType="Collapsible">
        <p>{`The expiration date can be found on the blister pack or banig where the medicines are placed.`}</p>
      </Collapsible>
      <Collapsible title="What are prescription medicines?" mdxType="Collapsible">
        <p>{`Prescription medicines are licensed medicines which `}<strong parentName="p">{`require a doctor's prescription`}</strong>{` to be obtained. This does not include vitamins, food supplements, and over-the-counter medicines.`}</p>
      </Collapsible>
      <h4>{`Regulatory`}</h4>
      <Collapsible title="What is MedGrocer?" mdxType="Collapsible">
        <p>{`MedGrocer is an FDA-licensed online pharmacy. Simply order your medicines online and we’ll deliver them to you. We carry both branded and generic medicines. We carry both prescription and over-the-counter medicines.`}</p>
      </Collapsible>
      <Collapsible title="Are your medicines FDA-certified?" mdxType="Collapsible">
        <p>{`Yes. The Certificate of Product Registration of a particular medicine can be found at the website of the `}<strong parentName="p">{`Food and Drug Administration`}</strong>{`.`}</p>
      </Collapsible>
      <ContactUs mdxType="ContactUs" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      